'use client';

import { logger } from '@/lib/logger';
import { useEffect } from 'react';
import { ErrorSection } from './(components)/ErrorSection';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    logger.error('Caught error in in error boundary', error);
  }, [error]);

  return <ErrorSection error={error} reset={reset} />;
}
